export default {
  list: [
    {
      chineseName: '一氧化碳',
      englishName: 'CO',
      num: 4
    },
    {
      chineseName: '二氧化氮',
      englishName: 'NO2',
      num: 21
    },
    {
      chineseName: '臭氧',
      englishName: 'O3',
      num: 13
    },
    {
      chineseName: '细颗粒物',
      englishName: 'PM2.5',
      num: 5
    },
    {
      chineseName: '超细颗粒物',
      englishName: 'PM1.0',
      num: 43
    },
    {
      chineseName: '二氧化硫',
      englishName: 'SO2',
      num: 1
    },
  ]
}